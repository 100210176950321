import React, { useEffect, useRef, useState, useMemo  } from "react";
import { useNavigate } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "./CompanyTable.css";
import { Button } from "primereact/button";
import { OverlayPanel } from "primereact/overlaypanel";
import { Dropdown } from "primereact/dropdown";

import { Dialog } from "primereact/dialog";
import { InputNumber } from "primereact/inputnumber";
import { ProgressSpinner } from "primereact/progressspinner";
import { InputText } from "primereact/inputtext";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
const token = localStorage.getItem("token");
const CompanyTable = React.memo(function CompanyTable({ data }) {
  console.log("Data:", data);
  const [rowData, setRowData] = useState(null);
  const [company, setCompany] = useState(data);
  const [filteredCompanies, setFilteredCompanies] = useState(data);

  const [loading, setLoading] = useState(true);
  const [showUpdatePlanDialog, setShowUpdatePlanDialog] = useState(false);
  const [showAssignLicensesDialog, setShowAssignLicensesDialog] =
    useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [licenses, setLicenses] = useState(null);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);

  
  const overlayPanelRef = useRef(null);

  const navigate = useNavigate();

  useEffect(() => {
    // Load saved global filter and sort options from localStorage
    const savedFilter = sessionStorage.getItem("globalFilter");
    const savedSortField = sessionStorage.getItem("sortField");
    const savedSortOrder = sessionStorage.getItem("sortOrder");

    if (savedFilter) {
      setGlobalFilter(savedFilter);
      onGlobalFilterChange(savedFilter);
    }
    if (savedSortField) {
      setSortField(savedSortField);
    }

    if (savedSortOrder) {
      setSortOrder(Number(savedSortOrder));
    }

    // Simulate loading delay
    setTimeout(() => {
      setLoading(false);
    }, 400);
  }, []); // Set the loading state to false after 0.400 seconds

  const onSort = (event) => {
    setSortField(event.sortField);
    setSortOrder(event.sortOrder);

    // Save sorting state in sessionStorage
    sessionStorage.setItem("sortField", event.sortField);
    sessionStorage.setItem("sortOrder", event.sortOrder);
};

useEffect(() => {
  // Save global filter to sessionStorage
  sessionStorage.setItem("globalFilter", globalFilter);
}, [globalFilter]);


  const handlePlanUpdateClick = (rowData) => {
    setRowData(rowData);
    console.log("Row data:", rowData);
    setShowUpdatePlanDialog(true);
  };
  const onGlobalFilterChange = (data) => {
    const lowercasedData = data.toLowerCase();

    const filteredData = company.filter((user) => {
      
      return (
        (user.companyName && user.companyName.toLowerCase().includes(lowercasedData)) ||
        (user.userEmail && user.userEmail.toLowerCase().includes(lowercasedData)) ||
        (user.plan && user.plan.toLowerCase().includes(lowercasedData))
        
      );
    });

    setFilteredCompanies(filteredData);
    setGlobalFilter(data);
  };
  const filteredCompaniesMemo = useMemo(() => {
    return filteredCompanies;
  }, [filteredCompanies]);

  const handlePlanUpdate = () => {
    const requestBody = {
      company: rowData.id,
      newPlan: selectedPlan,
    };
    console.log("Request body:", requestBody);
    axios
      .post(`${process.env.REACT_APP_BACKEND_LINK}/upgrade/plan`, requestBody, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          console.log("Training assigned successfully.");
        }
        toast.success("Training assigned successfully.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        window.location.reload();
      });

    console.log("Plan updated for:", rowData);
    console.log("Selected Plan:", selectedPlan);
    setShowUpdatePlanDialog(false);
  };
  const handleLicensesUpdate = () => {
    const requestBody = {
      company: rowData.id,
      license: licenses,
    };
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_LINK}/set/company/license`,
        requestBody,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          console.log("Training assigned successfully.");
          window.location.reload();
        }

        toast.success("Training assigned successfully.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });

    console.log("Plan updated for:", rowData);
    console.log("Selected Plan:", selectedPlan);
    setShowUpdatePlanDialog(false);
  };

  const planOptions = [
    { label: "Free", value: "Free" },
    { label: "Standard", value: "Standart" },
    { label: "Premium", value: "Premium" },
  ];

  const handleButtonClick = (event, rowData) => {
    setRowData(rowData);
    overlayPanelRef.current.toggle(event);
  };
  const handleLicenses = (rowData) => {
    setRowData(rowData);
    console.log("Row data:", rowData);
    setLicenses(rowData.payedLicense);
    setShowAssignLicensesDialog(true);
  };

  const handleDelete = async (rowData) => {
    console.log("Delete clicked for row data:", rowData);
    const id = localStorage.getItem("id");
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    const requestBody = {
      admin: id,
      companyID: rowData.id,
    };
    const response = await axios
      .post(
        process.env.REACT_APP_BACKEND_LINK + "/deleteCompany",
        requestBody,
        config
      )
      .catch((error) => {
        console.log(error);
        toast.error("Error deleting company profile.");
      });
    if (response.status === 200) {
      toast.success("Company profile deleted successfully.");
      window.location.reload();
    }
  };

  const overlayPanelContent = (
    <div className="button-box">
      <button
        onClick={() => handleLicenses(rowData)}
        className="button-panel assign-button"
      >
        Assign licenses
      </button>
      <button onClick={() => handleDelete(rowData)} className="button-panel">
        Delete profile
      </button>
      <button
        onClick={() => handlePlanUpdateClick(rowData)}
        className="button-panel"
      >
        Update plan
      </button>
    </div>
  );

  const generateRandomColor = () => {
    const hue = Math.floor(Math.random() * 360);
    const saturation = Math.floor(Math.random() * 100);
    const luminosity = Math.max(Math.floor(Math.random() * 50) + 50, 50);
    return `hsl(${hue}, ${saturation}%, ${luminosity}%)`;
  };
  const handleRowClick = (event) => {
    const clickedElement = event.originalEvent.target; // The exact element clicked
    console.log('Clicked element:', clickedElement);
  
    // Check if the clicked element is part of the company column
    if (
            clickedElement.closest('.company-name-column') // Check if it's anywhere inside the company row
    ) {
      // Prevent redirect for the entire company column
      event.originalEvent.preventDefault(); // Block redirect
      event.originalEvent.stopPropagation(); // Stop event propagation
      console.log('Company column clicked, redirect blocked.');
    } else {
      // Handle other row clicks
      const clickedRowData = event.data;
      console.log('Row clicked:', clickedRowData);
  
      // Proceed with navigation for other parts of the row
      navigate(`/company/${clickedRowData.id}`);
    }
  };
  

  const getBackgroundColor = (rowData) => {
    const key = `company-${rowData.id}-background-color`;
    let color = localStorage.getItem(key);

    if (!color) {
      color = generateRandomColor();
      localStorage.setItem(key, color);
    }

    return color;
  };

  const companyNameTemplate = (rowData) => {
    const initials = rowData.companyName.charAt(0).toUpperCase();
    const logoName = rowData.logo;
    const logoUrl = logoName
      ? `${process.env.REACT_APP_BACKEND_LINK}/logo/${logoName}`
      : null;

    return (
      <div className="company-row">
        <div
          className="company-logo"
          style={{
            backgroundColor: logoUrl ? "white" : getBackgroundColor(rowData),
            backgroundImage: logoUrl ? `url(${logoUrl})` : null,
            backgroundSize: "contain",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        >
          {!logoUrl && <div className="company-initials">{initials}</div>}
        </div>
        <div className="company-details">
          <div className="company-name">{rowData.companyName}</div>
          <div className="company-email">{rowData.userEmail}</div>
        </div>
      </div>
    );
  };

  const statusTemplate = (rowData) => {
    if (rowData.invoice === 0 && rowData.confirmed_email === 0) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            padding: "0px 8px 1px",
            gap: "10px",
            background: "#FFEBEE", // Soft red background
            borderRadius: "8px",
            width: "fit-content",
            height: "fit-content",
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "12px",
            lineHeight: "15px",
            letterSpacing: "0.035em",
            color: "#F44336", // Soft red color
            justifyContent: "center",
          }}
        >
          Not Verified
        </div>
      );
    } else {
      return rowData.status === 1 ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            padding: "0px 8px 1px",
            gap: "10px",
            background: "#DAFFF6",
            borderRadius: "8px",
            width: "fit-content",
            height: "fit-content",
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "12px",
            lineHeight: "15px",
            letterSpacing: "0.035em",
            color: "#4BC0A3",
            justifyContent: "center",
          }}
        >
          Active
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            padding: "0px 8px 1px",
            gap: "10px",
            background: "#FFF9E5",
            borderRadius: "8px",
            width: "fit-content",
            height: "fit-content",
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "12px",
            lineHeight: "15px",
            letterSpacing: "0.035em",
            color: "#FFCE3C",
            justifyContent: "center",
          }}
        >
          Invited
        </div>
      );
    }
  };
  

  const creationDateTemplate = (rowData) => {
    if (!rowData.date) {
        return "-"; // Return a dash if there is no date
    }

    // Create a new Date object from the rowData.date
    const date = new Date(rowData.date);

    // Format the date as "YYYY-MM-DD HH:mm:ss"
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

  
  const latestDateTemplate = (rowData) => {
    return rowData.latestDate ? rowData.latestDate : "-";
  };
  
  
  const rowNumberTemplate = (rowData, rowProps) => {
    return rowProps.rowIndex + 1;
  };

  return (
    <>
      <ToastContainer />
      {loading ? (
        <div className="loading-screen">
          <ProgressSpinner />
        </div>
      ) : (
        <div>
          <div className="p-mb-4">
                <div className="p-input-icon-left">
                  {/* <i style={{marginLeft:'10px'}} className="pi pi-search" /> */}
                  <InputText
                    type="search"                    
                    value={globalFilter}
                    // style={{paddingLeft:'30px'}}
                    onChange={(e) => onGlobalFilterChange(e.target.value)}
                    placeholder="Search name, email, or plan"
                  />
                </div>
              </div>
        <DataTable
          value={filteredCompaniesMemo}
          className="no-borders"
          rowClassName="hovered-row"
          paginator
        rows={50}
          onRowClick={handleRowClick}
          onSort={onSort} // Add onSort handler
          sortField={sortField} // Set sortField
          sortOrder={sortOrder} // Set sortOrder
    
          style={{ marginTop: "10px" }}
        >
          <Column
            body={rowNumberTemplate}
            header="#"
            style={{ width: "3em", textAlign: "center" }}
          />
          <Column
            field="companyName"
            header="Company name"
            sortable={true}
            body={companyNameTemplate}
            headerStyle={{
              borderTopLeftRadius: "0px",
              borderBottomLeftRadius: "0px",
            }}
             bodyClassName="company-name-column"
          />
          <Column
            field="status"
            header="Status"
            body={statusTemplate}
            sortable={true}
          />
          <Column field="plan" header="Plan" sortable={true} />
          <Column field="users" header="Users" sortable={true} />
          <Column field="devices" header="Devices" sortable={true} />
          <Column
            field="date"
            header="Date added"
            sortable={true}
            body={creationDateTemplate}
          />
          <Column
            field="latestDate"
            header="Last activity"
            sortable={true}
            body={latestDateTemplate}
          />
         
        </DataTable>
        </div>
      )}

      <OverlayPanel ref={overlayPanelRef}>
        {rowData && overlayPanelContent}
      </OverlayPanel>
      <Dialog
        visible={showUpdatePlanDialog}
        onHide={() => setShowUpdatePlanDialog(false)}
        header={<div style={{ marginTop: "-25px" }}>Update Plan</div>}
        modal
        style={{ width: "450px", height: "300px" }}
        footer={
          <div
            style={{ display: "flex", justifyContent: "center", gap: "10px" }}
          >
            <Button
              label="Cancel"
              className="custom-buttonq"
              onClick={() => setShowUpdatePlanDialog(false)}
            />
            <Button
              label="Update"
              className="custom-button"
              onClick={handlePlanUpdate}
              disabled={!selectedPlan}
            />
          </div>
        }
      >
        <div style={{ width: "392px", marginTop: "5px" }}>
          <Dropdown
            value={selectedPlan}
            options={planOptions}
            onChange={(e) => setSelectedPlan(e.value)}
            placeholder="Select Plan"
            style={{
              width: "100%",
              height: "36px",
              display: "flex",
              alignItems: "center",
            }}
          />
        </div>
      </Dialog>
      <Dialog
        visible={showAssignLicensesDialog}
        onHide={() => setShowAssignLicensesDialog(false)}
        header={<div style={{ marginTop: "-25px" }}>Assign licenses</div>}
        modal        
        style={{ width: "450px", height: "225px" }}
        footer={
          <div
            style={{ display: "flex", justifyContent: "center", gap: "10px" }}
          >
            <Button
              label="Cancel"
              className="custom-buttonq"
              onClick={() => setShowAssignLicensesDialog(false)}
            />
            <Button
              label="Update"
              className="custom-button"
              onClick={handleLicensesUpdate}
              disabled={true} //{!licenses}
            />
          </div>
        }
      >
        <div>
          <div style={{ marginLeft: "100px" }}>
            <InputNumber
              className="input-field"
              value={licenses}
              onChange={(e) => setLicenses(e.value)}
              placeholder="Enter number of licenses"
              showButtons
              buttonLayout="horizontal"
              style={{ width: "50px" }}
              inputStyle={{ width: "80px" }}
              decrementButtonClassName="p-button-secondary plus-button"
              incrementButtonClassName="p-button-secondary plus-button"
              incrementButtonIcon="pi pi-plus"
              decrementButtonIcon="pi pi-minus"
            />
          </div>
        </div>
      </Dialog>
    </>
  );
});
export default CompanyTable;
